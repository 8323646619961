<template>
  <!--begin::details View-->
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
    <!--begin::Card header-->
    <div class="card-header cursor-pointer">
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">จัดการ</h3>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->
    <!--begin::Card body-->
    <div class="card-body p-9">
      <!--begin::Row-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">สถานะ</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bolder fs-6 text-dark">{{ data.status }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
     
      <Form
        @submit="onSubmitTicket"
        :validation-schema="ticket"
        id="kt_account_profile_details_form"
        class="form"
        novalidate="novalidate"
      >
        <BaseInput name="id" type="hidden" v-model="id" />
        <BaseInput
          required="required"
          label="รายละเอียดการแจ้งกลับ"
          as="textarea"
          name="messaage_return"
        />
        <!--begin::Row-->
        <div class="row mb-7">
          <!--begin::Label-->
          <label class="col-lg-4 fw-bold text-muted"></label>
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-8">
            <button
              type="submit"
              id="kt_account_profile_details_submit"
              ref="submitButton"
              class="btn btn-primary"
            >
              <span class="indicator-label"> ยืนยัน </span>
              <span class="indicator-progress">
                กรุณารอสักครู่ ...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </Form>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/modals/BaseInput.vue";
import { defineComponent, computed, ref } from "vue";
import moment from "moment";
import useSweetalert from "@/core/helpers/sweetalert2";
import useApi from "@/core/services/api/building_permits";
import { Form } from "vee-validate";
import * as Yup from "yup";

export default defineComponent({
  name: "tax-show-manage",
  props: ["data"],
  components: {
    Form,
    BaseInput
  },
  setup(props, { emit }) {
    const { Sconfirm, SToast } = useSweetalert();
    const { FinishAticket } = useApi();
    const ticket = Yup.object().shape({
        messaage_return: Yup.string().required("กรุณากรอก รายละเอียด").label("รายละเอียด"),
    });
    const date = ref();


    const id = computed(() => {
      return props.data.id;
    });


    const onSubmitTicket = async (values) => {
      values.due_date = date.value;
      Sconfirm("ยืนยัน การปิดงาน", "question").then(async (result) => {
        if (result.isConfirmed) {
          emit("loader");
          await FinishAticket(values)
            .then(() => {
              SToast("success", "การปิดงาน ใบงาน สำเร็จ");
              emit("getAticket");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                SToast("error", errors.message);
              }
            });
        }
      });
    };

    function dateThai(date) {
      var y = moment(date).format("yyy");
      var year = parseInt(y) + 543;
      var march = moment(date).locale("th");

      return march.format("D MMM" + year + ", LT");
    }

    return {
      dateThai,
      ticket,
      onSubmitTicket,
      id,
      date,
    };
  },
});
</script>

<style scoped>
.tt {
  margin-left: 20px;
  font-weight: bold;
}
.bt-delete {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px !important;
  border-radius: 0px;
}
.image-logo {
  width: 250px;
  height: 250px;
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
  border: 1px solid #eff2f5;
}
.btn-new-icon {
  width: 250px;
  height: 250px;
  background-color: #f5f8fa;
}
.icon-ima {
  width: 30px;
  margin-left: 5px;
}
.btn-outline-secondary {
  border: 1px solid var(--color) !important;
}
.images {
  width: 100%;
}
.xx {
  position: absolute;
  background-color: #bd313e;
  color: white;
  right: 12px;
  padding: 6px;
}
.addimage {
  border: 1px solid var(--color) !important;
}
</style>
